/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import { AuthProvider } from './src/context/Auth'
import { Authentication } from '@Components/Authentication'
import { FilterProvider } from '@Contexts/Filter'
import { Helmet } from 'react-helmet'
import React from 'react'

export const wrapRootElement = ({ element }) =>
    <FilterProvider>
        <AuthProvider>
            {element}
        </AuthProvider>
    </FilterProvider>

export const wrapPageElement = ({ element, props }) =>
    <Authentication path={props.path} hash={props.location.hash}>
        <Helmet>
            <title>Carvantedge</title>
            <meta charset="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="theme-color" content="#000000" />
            <link href="https://fonts.googleapis.com/css?family=Heebo:400,500,700,800,900|Montserrat:400,500,700,800,900|PT+Serif:400,500,700,800,900|Roboto:400,500,700,800,900&display=swap" rel="stylesheet" />
        </Helmet>
        {element}
    </Authentication>
