const actionTypes = {
    /** Updates both the absolute bounds and the user selection for the Price filter.
     * 
     * Payload object is expected to include only properties that consumer expects to update.
     * @param payload.[currentLow] The lower range of the user selection 
     * @param payload.[currentHigh] The upper range of the user selection
     * @param payload.[lowerBound] The lower limit of the selectable range
     * @param payload.[upperBound] The upper limit of the selectable range
     * */
    PRICE: 'PRICE',
    /** Updates both the absolute bounds and the user selection for the Mileage filter.
     * 
     * Payload object is expected to include only properties that consumer expects to update.
     * @param payload.[currentLow] The lower range of the user selection 
     * @param payload.[currentHigh] The upper range of the user selection
     * @param payload.[lowerBound] The lower limit of the selectable range
     * @param payload.[upperBound] The upper limit of the selectable range
     * */
    MILEAGE: 'MILEAGE',
    /** Updates both the absolute bounds and the user selection for the Years filter.
     * 
     * Payload object is expected to include only properties that consumer expects to update.
     * @param payload.[currentLow] The lower range of the user selection 
     * @param payload.[currentHigh] The upper range of the user selection
     * @param payload.[lowerBound] The lower limit of the selectable range
     * @param payload.[upperBound] The upper limit of the selectable range
     * */
    YEARS: 'YEARS',
    /** Updates user selected car makes to store in the filter.
     *  @param payload An object representing the new enabled status of each make. The given object will be merged against the existing state.
     */
    MAKE_OPTIONS: 'MAKE_OPTIONS',
    /** Updates both the absolute bounds and the user selection for the Availability filter.
     * 
     * Payload object is expected to include only properties that consumer expects to update.
     * @param payload.[currentLow] The lower range of the user selection 
     * @param payload.[currentHigh] The upper range of the user selection
     * @param payload.[lowerBound] The lower limit of the selectable range
     * @param payload.[upperBound] The upper limit of the selectable range
     * */
    AVAILABILITY: 'AVAILABILITY',
    SORT_BY: 'SORT_BY',
    RADIUS: 'RADIUS',
    ZIPCODE: 'ZIPCODE',
    RESET_FILTERS: 'RESET_FILTERS',
    SEARCH_QUERY: 'SEARCH_QUERY',
    SET_PAGE: 'SET_PAGE',
    NEED_FILTER_STATE: 'NEED_FILTER_STATE',
    HYDRATE_FILTER_STATE: 'HYDRATE_FILTER_STATE'
}

Object.freeze(actionTypes)

export { actionTypes }