import { actionTypes } from './actionTypes'
import { initialState } from './initialState'

let defaultFilterState = initialState

const isActive = (bound, value) => (String(bound.min) !== String(value.currentLow) || String(bound.max) !== String(value.currentHigh))

export const FilterReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.PRICE: {
      const price = { ...state.price, ...action.payload }
      const active = {
        ...state.active,
        price: isActive(
          { min: price.lowerBound, max: price.upperBound },
          { currentLow: price.currentLow, currentHigh: price.currentHigh }
        )
      }

      return { ...state, price, active }
    }
    case actionTypes.MILEAGE: {
      const mileage = { ...state.mileage, ...action.payload }
      const active = {
        ...state.active,
        miles: isActive(
          { min: mileage.lowerBound, max: mileage.upperBound },
          { currentLow: mileage.currentLow, currentHigh: mileage.currentHigh }
        )
      }

      return { ...state, mileage, active }
    }
    case actionTypes.YEARS: {
      const years = { ...state.years, ...action.payload }
      const active = {
        ...state.active,
        years: isActive(
          { min: years.lowerBound, max: years.upperBound },
          { currentLow: years.currentLow, currentHigh: years.currentHigh }
        )
      }

      return { ...state, years, active }
    }
    case actionTypes.MAKE_OPTIONS:
      const make = { ...state.make, ...action.payload }

      return {
        ...state,
        make,
        active: {
          ...state.active,
          makes: Object.values(make).includes(true)
        }
      }
    case actionTypes.AVAILABILITY: {
      const availability = { ...state.availability, ...action.payload }
      const active = {
        ...state.active,
        availability: isActive(
          { min: availability.lowerBound, max: availability.upperBound },
          { currentLow: availability.currentLow, currentHigh: availability.currentHigh }
        )
      }

      return { ...state, availability, active }
    }
    case actionTypes.SORT_BY:
      return { ...state, sortBy: action.payload, pageNumber: 0 }
    case actionTypes.RADIUS:
      return {
        ...state,
        radius: action.payload,
        pageNumber: 0,
        active: {
          ...state.active,
          location: !!state.zipcode
        }
      }
    case actionTypes.ZIPCODE:
      return {
        ...state,
        zipcode: action.payload,
        pageNumber: 0,
        active: {
          ...state.active,
          location: !!action.payload
        }
      }
    case actionTypes.RESET_FILTERS:
      return { ...defaultFilterState, pageNumber: 0 }
    case actionTypes.SEARCH_QUERY:
      return { ...state, searchString: action.payload, pageNumber: 0 }
    case actionTypes.SET_PAGE:
      return { ...state, pageNumber: action.payload }
    case actionTypes.NEED_FILTER_STATE:
      return { ...state, needFiltersState: action.payload }
    case actionTypes.HYDRATE_FILTER_STATE:
      const defaultMakes = action.payload.make?.reduce((accumulator, thisMake) => ({ ...accumulator, [thisMake]: false }), {})

      defaultFilterState = {
        ...state,
        ...action.payload,
        make: { ...defaultMakes },
        availability: {
          lowerBound: action.payload.minAvailability || state.availability.lowerBound,
          upperBound: action.payload.maxAvailability || state.availability.upperBound,
          currentLow: action.payload.minAvailability || state.availability.currentLow,
          currentHigh: action.payload.maxAvailability || state.availability.currentHigh
        },
        price: {
          lowerBound: action.payload.minPrice || state.price.lowerBound,
          upperBound: action.payload.maxPrice || state.price.upperBound,
          currentLow: action.payload.minPrice || state.price.currentLow,
          currentHigh: action.payload.maxPrice || state.price.currentHigh
        },
        mileage: {
          lowerBound: action.payload.minMileage || state.mileage.lowerBound,
          upperBound: action.payload.maxMileage || state.mileage.upperBound,
          currentLow: action.payload.minMileage || state.mileage.currentLow,
          currentHigh: action.payload.maxMileage || state.mileage.currentHigh
        },
        years: {
          lowerBound: action.payload.minYear || state.years.lowerBound,
          upperBound: action.payload.maxYear || state.years.upperBound,
          currentLow: action.payload.minYear || state.years.currentLow,
          currentHigh: action.payload.maxYear || state.years.currentHigh
        },
        needFiltersState: false
      }

      return defaultFilterState
    default:
      return { ...state }
  }
}