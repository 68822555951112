import { AuthContext, actionTypes } from '@Contexts/Auth'
import React, { useContext, useEffect } from 'react'

import { Layout } from '@Components/Layout'
import { getCredentials } from '@Services/Credentials'
import { logError } from '@Services'
import { navigate } from 'gatsby'

export const Authentication = ({ path, hash, children }) => {
    const { state, dispatch } = useContext(AuthContext)
    const credentials = getCredentials()

    //Attempts to pre-authorize user
    useEffect(() => {
        //Skipping if already authorized.
        if (state.authorized) return

        //Attempting to log in through stashed credentials
        if (credentials) {
            dispatch({ type: actionTypes.AUTHORIZE, ...credentials })
            return
        }

        //Attempting to log in through passthru
        if (hash) {
            navigate('/Login/', { state: { hash } })
            return
        }

        //Hijacking navigation and sending user to login page if all else fails
        navigate('/Login/')
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [credentials?.token])

    //Logs and rescues user from undefined route.
    //Hasn't actually happened yet, but it can, so we need to handle it.
    useEffect(() => {
        if (typeof path !== 'undefined') return

        logError('Path was undefined. Dumping user at Login')
        navigate('/Login/')
    }, [path])

    return <Layout path={path}>
        {children}
    </Layout>
}