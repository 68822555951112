import { expectedTokenExpiry, storageKey } from './utilities'
import { getItem, isAvailable } from './storage'

import { logError } from '@Services/logging'

/**Attempts to fetch the credentials package from persistent storage.
 * 
 * @returns {Object|Boolean} The credentials object if fetching was successful and the token was not 
 * expired. False if the package was expired or could not be retrieved.
 */
export const getCredentials = () => {
    try {
        if (!isAvailable()) return false

        const cache = JSON.parse(getItem(storageKey))

        if (!cache) return false

        const expiry = new Date(cache.timestamp)
        expiry.setMinutes(expiry.getMinutes() + expectedTokenExpiry)

        return expiry >= new Date() ? { token: cache.token, username: cache.username } : false
    } catch (error) {
        logError(error)
        return false
    }
}