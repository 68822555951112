import React from 'react'

const Terms = () =>
  <div className='legal'>
    <div className='legal-title'>Terms and Conditions</div>
    <div className='legal-section-title'>Acceptance of Terms of Use</div>
    <div className='legal-section-text'>
      IT IS IMPORTANT THAT YOU READ ALL THE TERMS AND CONDITIONS CAREFULLY. Fleet Street Remarketing Two,
      LLC owns and operates this website, located at https://www.carvantedge.com ("the Website"). This Terms
      of Use Agreement (this "Agreement") states the terms and conditions under which you may access and
      use the Website. By accessing and using the Website, you are indicating your acceptance to be bound
      by the terms and conditions of this Agreement. If you do not accept these terms and conditions, you
      must not access or use the Website. IF YOU ARE DISSATISFIED WITH THE TERMS, CONDITIONS, RULES,
      POLICIES, GUIDELINES OF THE WEBSITE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING IT. Fleet
      Street Remarketing Two, LLC may revise this Agreement at any time by updating this posting. Use of the
      Website after such changes are posted will signify your agreement to these revised terms. You should
      visit this page periodically to review this Agreement.
      </div>

    <div className='legal-section-title'>Copyright</div>
    <div className='legal-section-text'>
      All materials displayed or otherwise accessible through the Website, including, without limitation, news
      articles, text, photographs, images, illustrations, (collectively, "the Content") are protected under
      U.S. and foreign copyright or other laws, and are owned by Fleet Street Remarketing Two, LLC, or the
      party accredited as the provider of the Content. In addition, the Website is protected under copyright
      law as a collective work and/or compilation pursuant to U.S. and foreign laws. You shall abide by all
      additional copyright notices, information and restrictions on or contained in any of the Content accessed
      through the Website. You may use the Contents for private, noncommercial use.
      </div>

    <div className='legal-section-title'>Disclaimer of Warranties</div>
    <div className='legal-section-text'>
      BY ACCESSING AND USING THE WEBSITE YOU ACKNOWLEDGE AND AGREE THAT USE OF THE WEBSITE AND THE CONTENT IS
      ENTIRELY AT YOUR OWN RISK. Fleet Street Remarketing Two, LLC MAKES NO REPRESENTATIONS OR WARRANTIES
      REGARDING THE WEBSITE AND THE CONTENT INCLUDING, WITHOUT LIMITATION, NO REPRESENTATION OR WARRANTY (I)
      THAT THE WEBSITE AND/OR CONTENT WILL BE ACCURATE, COMPLETE, RELIABLE, SUITABLE OR TIMELY, (II) THAT THE
      OPERATION OF THE WEBSITE WILL BE UNINTERRUPTED OR ERROR-FREE, (III) THAT DEFECTS OR ERRORS IN THE WEBSITE
      WILL BE CORRECTED, OR (IV) THAT WEBSITES LINKED TO FROM THIS WEBSITE WILL BE ACCURATE, COMPLETE, RELIABLE,
      SUITABLE OR TIMELY.
      </div>

    <div className='legal-section-title'>Limitation of Liability</div>
    <div className='legal-section-text'>
      SUBJECT TO APPLICABLE LAW, IN NO EVENT SHALL Fleet Street Remarketing Two, LLC, ITS OFFICERS, DIRECTORS,
      EMPLOYEES, AGENTS, SUB-CONTRACTORS, OR THEIR RESPECTIVE SUCCESSORS AND ASSIGNS, BE LIABLE FOR ANY SPECIAL,
      INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING, WITHOUT LIMITATION, ANY LOSS OR DAMAGES
      IN THE NATURE OF OR RELATING TO LOST BUSINESS, LOST SAVINGS, LOST DATA AND/OR LOST PROFITS, REGARDLESS OF THE
      CAUSE AND WHETHER ARISING IN CONTRACT (INCLUDING FUNDAMENTAL BREACH), TORT (INCLUDING NEGLIGENCE), OR OTHERWISE.
      </div>
    <div className='legal-section-text'>
      UNDER NO CIRCUMSTANCES SHALL Fleet Street Remarketing Two, LLC AND ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS,
      SUB-CONTRACTORS, BE LIABLE FOR ANY INJURY, LOSS, DAMAGE (INCLUDING SPECIAL, INDIRECT, INCIDENTAL OR
      CONSEQUENTIAL DAMAGES), OR EXPENSE ARISING IN ANY MANNER WHATSOEVER ARISING FROM THE CONTENT OF ANY THIRD-PARTY WEBSITE.
      </div>

    <div className='legal-section-title'>Linking</div>
    <div className='legal-section-text'>
      The Website contains links to third-party websites. These links are provided solely as a convenience to you
      and not as an endorsement by Fleet Street Remarketing Two, LLC of the contents of such third-party websites.
      Fleet Street Remarketing Two, LLC is not responsible for the content of any third-party website, nor does it
      make any representation or warranty of any kind regarding any third-party website including, without limitation
      (I) any representation or warranty regarding the legality, accuracy, reliability, completeness, timeliness,
      suitability of any content on any third-party website, or (II) any representation or warranty that the operation
      of the third-party websites will be uninterrupted or error free, that defects or errors in such third-party
      websites will be corrected, or that such third-party websites will be free from viruses or other harmful components.
      </div>

    <div className='legal-section-title'>Governing Laws & Jurisdiction</div>
    <div className='legal-section-text'>
      The Website is controlled by Fleet Street Remarketing Two, LLC from offices within the State of Florida. By accessing
      or using the Website, you agree that all matters relating to your access to, or use of, the Website and/or the
      Content shall be governed by the laws of the State of Florida and the laws of the U.S., without regard to the
      conflict of laws principles thereof. You agree and hereby submit to the non-exclusive jurisdiction the courts
      of the State of Florida with respect to such matters.
      </div>

    <div className='legal-section-title'>General</div>
    <div className='legal-section-text'>
      Any consent by Fleet Street Remarketing Two, LLC, or waiver of, a breach of this Agreement which you have
      committed, whether express or implied, shall not constitute a consent to, or waiver of any other, different
      or subsequent breach. If any term or provision of this Agreement is held by a court of competent jurisdiction
      to be invalid, it shall be severed and the remaining provisions shall remain in full force without being
      invalidated in any way. Except for any agreements with Fleet Street Remarketing Two, LLC that expressly
      reference this Agreement, this is the entire agreement between you and Fleet Street Remarketing Two, LLC
      relating to the matters contained herein.
      </div>
  </div>

export default Terms
