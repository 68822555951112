import React, { useReducer } from 'react'

import { AuthContext } from './AuthContext'
import { AuthReducer } from './AuthReducer'
import { initialState } from './initialState'

export function AuthProvider({ children }) {
    const [state, dispatch] = useReducer(AuthReducer, initialState)

    return <AuthContext.Provider value={{ state, dispatch }}>
        {children}
    </AuthContext.Provider>
}
