import { graphql, useStaticQuery } from 'gatsby'

import Img from 'gatsby-image'
import React from 'react'

const getPropsForLocation = (data, location) => {
    switch (location) {
        case 'login':
            return { fixed: data.file.childImageSharp.login, durationFadeIn: 50 }
        case 'header':
            return { fixed: data.file.childImageSharp.header, durationFadeIn: 50 }
        default:
            return null
    }
}

export const CarvantedgeLogo = props => {
    const data = useStaticQuery(graphql`
    query {
        file(relativePath: { eq: "logo-header@2x.png" }) {
            childImageSharp {
                login: fixed(width: 330) {
                    ...GatsbyImageSharpFixed_withWebp
                }
                header: fixed(width: 200) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
    }
    `)

    return <Img {...getPropsForLocation(data, props.location)} {...props} />
}