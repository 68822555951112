import { getItem, isAvailable, setItem } from './storage'

import { logError } from '@Services/logging'
import { storageKey } from './utilities'

/**Updates the timestamp stored with the authentication token to the current date and time.
 * 
 * @returns {Boolean} True if the timestamp was successfully updated, false otherwise.
 */
export const updateTimestamp = () => {
    try {
        if (!isAvailable()) return false

        const parsedAuthentication = JSON.parse(getItem(storageKey))

        parsedAuthentication.timestamp = (new Date().toString())

        const payload = JSON.stringify(parsedAuthentication)

        setItem(storageKey, payload)

        return getItem(storageKey) === payload
    } catch (error) {
        logError(error)
        return false
    }
}