import { storageKey } from './utilities'
import { removeItem, isAvailable, getItem } from './storage'

import { logError } from '@Services/logging'

export const removeCredentials = () => {
    try {
        if (!isAvailable()) return false

        removeItem(storageKey)

        return !getItem(storageKey)
    } catch (error) {
        logError(error)
        return false
    }
}