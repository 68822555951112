import './styles.scss'

import { Col, Row } from 'react-bootstrap'
import React, { Fragment, useState } from 'react'

import IconFB from '@Assets/icon-fb.png'
import IconInsta from '@Assets/icon-insta.png'
import IconLogo from '@Assets/icon-logo.png'
import { Link } from 'gatsby'
import PrivacyPolicy from '@Components/shared/Legal/PrivacyPolicy'
import ReactModal from 'react-modal'
import Terms from '@Components/shared/Legal/Terms'

const Footer = () => {
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false)
  const [isTermsOpen, setIsTermsOpen] = useState(false)

  return <Row className='footer'>
    <Fragment>
      <Col className='footer-car-icon'>
        <Link to='/About/'><img src={IconLogo} alt='carvantage-logo' /></Link>
      </Col>
      <Col className='footer-first-bold-column footer-text-bold'>
        <Link to='/About/'><div>ABOUT</div></Link>
        <Link to='/Contact/'><div>CONTACT</div></Link>
        <Link to='/Search/'><div>SHOP</div></Link>
      </Col>
      <Col className='footer-text-bold'>
        <Link to='/Warranty/'><div>WARRANTY</div></Link>
        <Link to='/Inspection/'><div>INSPECTION</div></Link>
        <Link to='/Detailing/'><div>DETAILING</div></Link>
        <Link to='/Shipping/'><div>SHIPPING</div></Link>
      </Col>
      <Col className='footer-text-plain footer-last-text-link-column'>
        {/* TERMS & CONDITIONS MODAL */}
        <button
          type='button'
          className='link-button footer-text-plain-terms'
          onClick={() => setIsTermsOpen(true)}
        >
          Terms &amp; Conditions
        </button>
        <ReactModal
          ariaHideApp={false}
          isOpen={isTermsOpen}
          contentModal='Privacy Policy Modal'
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => setIsTermsOpen(false)}
          style={{
            overlay: {
              zIndex: 99,
            }
          }}
        >
          <div data-testid='terms-modal'>
            <div
              role='button'
              tabIndex={0}
              className='modal-close'
              onClick={() => setIsTermsOpen(false)}
              onKeyPress={() => setIsTermsOpen(false)}
            >
              CLOSE
              </div>
            <Terms />
          </div>
        </ReactModal>
        {/* PRIVACY POLICY MODAL */}
        <button
          data-testid='privacy-modal-open'
          type='button'
          className='link-button footer-text-plain-privacy'
          onClick={() => setIsPrivacyOpen(true)}
        >
          Privacy Policy
        </button>
        <ReactModal
          ariaHideApp={false}
          isOpen={isPrivacyOpen}
          contentModal='Privacy Policy Modal'
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => setIsPrivacyOpen(false)}
          style={{
            overlay: {
              zIndex: 99,
            }
          }}
        >
          <div data-testid='privacy-modal'>
            <div
              role='button'
              tabIndex={0}
              data-testid='privacy-modal-close'
              className='modal-close'
              onClick={() => setIsPrivacyOpen(false)}
              onKeyPress={() => setIsPrivacyOpen(false)}
            >
              CLOSE
              </div>
            <PrivacyPolicy />
          </div>
        </ReactModal>
        <div>&copy;2019 - {new Date().getFullYear()} CarVandtedge.com. All rights reserved.</div>
      </Col>
      <Col className='footer-social-media-buttons'>
        <div className='footer-icons'>
          <div className='footer-icons-fb'>
            <a href='https://www.facebook.com/CarVantedge' rel='noopener noreferrer' target='_blank'>
              <div className='footer-icons-icon'>
                <img src={IconFB} alt='facebook-icon' />
              </div>
            </a>
          </div>
          <div className='footer-icons-insta'>
            <a href='https://www.instagram.com/carvantedge/' rel='noopener noreferrer' target='_blank'>
              <div className='footer-icons-icon'>
                <img src={IconInsta} alt='instagram-icon' />
              </div>
            </a>
          </div>
        </div>
      </Col>
    </Fragment>
  </Row>
}

export default Footer
