import './styles.scss'

import { AuthContext, actionTypes } from '@Contexts/Auth'
import { Nav, NavItem, Navbar } from 'react-bootstrap'
import React, { useContext } from 'react'

import { CarvantedgeLogo } from '@Components/shared/Images'
import { Link } from 'gatsby'

const links = [
  { to: '/About/', text: 'ABOUT' },
  { to: '/Search/', text: 'SHOP' },
  { to: '/Warranty/', text: 'WARRANTY' },
  { to: '/Inspection/', text: 'INSPECTION' },
  { to: '/Shipping/', text: 'SHIPPING' },
  { to: '/Detailing/', text: 'DETAILING' }
]

const NavBar = () => {
  const { dispatch } = useContext(AuthContext)

  return <div className="nav-bar">
    <Navbar className="justify-content-around" collapseOnSelect expand="md">
      <Navbar.Brand>
        <Link to="/About/">
          <CarvantedgeLogo className='nav-bar-logo' location='header' alt='CarVantedge' />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" className="mobile-toggle" />
      <Navbar.Collapse id="basic-navbar-nav" className="mobile-collapse">
        <Nav className="mr-auto">
          {links.map(({ to, text }, index) =>
            <NavItem key={index} className="ml-4 mobile-item" eventkey={index}>
              <Link to={to} activeClassName='active'>{text}</Link>
            </NavItem>)}
        </Nav>
        <Nav>
          <NavItem
            data-testid='logout-navitem'
            className="ml-4 mobile-item"
            eventkey={7}
            onClick={() => dispatch({ type: actionTypes.DEAUTHORIZE })}>
            LOG OUT</NavItem>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </div>
}


export default NavBar
