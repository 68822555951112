import { graphql, useStaticQuery } from 'gatsby'

import Img from 'gatsby-image'
import React from 'react'

export const ILendingLogo = props => {
    const data = useStaticQuery(graphql`
    query {
        file(relativePath: { eq: "ilendingdirectlogo.png" }) {
            childImageSharp {
                fluid(maxWidth: 225) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
    `)

    return <Img fluid={data.file.childImageSharp.fluid} {...props} />
}