import './styles.scss'

import React from 'react'

const PrivacyPolicy = () =>
  <div className='legal'>
    <div className='legal-title'>Privacy Policy</div>
    <div className='legal-section-text'>
      Fleet Street Remarketing Two, LLC has created this privacy policy because they value
      their customers and recognize their right to keep personal information private. This
      statement discloses information gathering and dissemination practices for the website,
      located at https://www.carvantedge.com ("the Website"). Fleet Street Remarketing Two,
      LLC will never willfully disclose personally identifiable information about their
      customers to any third party without first receiving that customer’s permission.
      <p>If a customer has questions or concerns regarding the above statement, they should contact:</p>
      <p>Fleet Street Remarketing Two, LLC 11522 Seminole Blvd Largo, FL 33778 (800) 539-2277 info@fleetstreetusa.com</p>
    </div>

    <div className='legal-section-title'>Information Collection, Use and Sharing</div>
    <div className='legal-section-text'>Fleet Street Remarketing Two, LLC is the sole owner of the information collected on
    the Website. Fleet Street Remarketing Two, LLC collects information from their users
    at various points on our Website.
    </div>

    <div className='legal-section-title'>Information Use</div>
    <div className='legal-section-text'>
      Fleet Street Remarketing Two, LLC does not sell, rent or release this information to any outside
      vendor or company without prior consent. All information is kept strictly confidential.
      nt in the aggregate, and gather broad demographic information for aggregate use.
    </div>

    <div className='legal-section-title'>Legal Disclaimer</div>
    <div className='legal-section-text'>
      Though we make every effort to preserve user privacy, we may need to disclose personal
      information when required by law wherein we have a good-faith belief that such action
      is necessary to comply with a current judicial proceeding, a court order or legal process served on our Website.
    </div>

    <div className='legal-section-title'>Links</div>
    <div className='legal-section-text'>
      This Website contains links to other sites. Please be
      aware that Fleet Street Remarketing Two, LLC is not responsible for the privacy practices of
      such other sites. We encourage our customers to be aware when they leave our site and to read
      the privacy statements of each and every Website that collects personally identifiable
      information. This privacy statement applies solely to information collected by this Website.
    </div>

    <div className='legal-section-title'>Contact Information</div>
    <div className='legal-section-text'>
      If a customer has any questions or suggestions regarding our privacy policy, please contact us at:

      <p>Fleet Street Remarketing Two, LLC</p>
      <p>11522 Seminole Blvd</p>
      <p>Largo, FL 33778</p>
      <p>(800) 539-2277</p>
      <p>info@fleetstreetusa.com</p>
      <p>© 2019 Fleet Street Remarketing Two, LLC. ALL RIGHTS RESERVED</p>
    </div>
  </div>

export default PrivacyPolicy
