/**Gets a persisted value from storage.
 * 
 * @param {String} key The key to fetch a persisted value by.
 */
export const getItem = key => localStorage.getItem(key)

/**Persists a given value during a browser session, given a key and value.
 * 
 * @param {String} key The key to persist and retrieve the value by.
 * @param {String} value The value to persist.
 */
export const setItem = (key, value) => localStorage.setItem(key, value)

/**Removes a persisted item, clearing the value from the browser session.
 * 
 * @param {String} key The key to clear the value of.
 */
export const removeItem = key => localStorage.removeItem(key)

/**Checks that storage is available.
 * 
 * @returns {Boolean} True if storage is available, false if not.
 */
export const isAvailable = () => (!!localStorage?.getItem && !!localStorage?.setItem)
