export const initialState = {
    availability: { lowerBound: 0, upperBound: 6, currentLow: 0, currentHigh: 6 },
    price: { lowerBound: 0, upperBound: 100000, currentLow: 0, currentHigh: 100000 },
    mileage: { lowerBound: 0, upperBound: 999999, currentLow: 0, currentHigh: 999999 },
    years: { lowerBound: 1990, upperBound: 2020, currentLow: 1990, currentHigh: 2020 },
    radius: 'any',
    zipcode: '',
    make: {},
    sortBy: 'Highest%20Mileage',
    searchString: '',
    active: {
        availability: false,
        location: false,
        price: false,
        miles: false,
        years: false,
        makes: false,
    },
    resetFilters: false,
    needFiltersState: true,
    pageNumber: 0,
}