import './layout.css'

import React, { Fragment } from 'react'

import Footer from './Footer/Footer'
import Navbar from './Navbar/Navbar'

const unprivilegedPaths = ['/Login/', '/']

export const Layout = ({ children, path }) => {
  const isPrivileged = !unprivilegedPaths.includes(path)
  
  return <Fragment>
    {isPrivileged && <Navbar />}
    {children}
    {isPrivileged && <Footer />}
  </Fragment>
}
