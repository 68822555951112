/**Environment aware error logger.
 * Switches logging implementation based on deployment environment.
 * 
 * This should be used in place of `console.error`.
 * @param {Error} error The thrown error
 */
export const logError = error => {
    switch (process.env.NODE_ENV) {
        case 'development':
            console.error(error)
            break
        case 'production':
            //TODO: Log to something like Sentry
            break
        case 'test':
            break
        default:
            console.log(`Unimplemented environment '${process.env.NODE_ENV}'. Dumping Error to console.`)
            console.log(error)
            break
    }
}