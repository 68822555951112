import { getItem, isAvailable, setItem } from './storage'

import { logError } from '@Services/logging'
import { storageKey } from './utilities'

/**Stores authentication credentials in persistent storage for reuse.
 * 
 * @param {String} credentials The credentials package to store.
 * @returns {Boolean} True if the package was successfully saved, false otherwise.
 */
export const saveCredentials = credentials => {
    try {
        if (!isAvailable()) return false

        const payload = JSON.stringify({
            ...credentials,
            timestamp: new Date()
        })

        setItem(storageKey, payload)

        return getItem(storageKey) === payload
    } catch (error) {
        logError(error)
        return false
    }
}
