import { actionTypes } from '../context/Auth'
import { navigate } from 'gatsby'
import { removeCredentials } from './Credentials'

/**Handles deauthorizing the user and pushing them from wherever
 * they currently are navigated back to the login screen.
 * 
 * @param {Error} error The error emitted by axios
 * @param {Function} [dispatch] A scope appropriate reference to the dispatch callback for FilterContext
 * @returns {Boolean} True if error was applicable and handled by function, false otherwise
 */
export const handleUserDeauth = (error, dispatch) => {
    if (error.response?.status === 401) {
        removeCredentials()
        dispatch && dispatch({ type: actionTypes.DEAUTHORIZE })
        navigate('/Login/')

        return true
    }
    return false
}