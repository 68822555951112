import { removeCredentials, saveCredentials } from '@Services/Credentials'

import { actionTypes } from './actionTypes'
import { initialState } from './initialState'

export const AuthReducer = (state, { type, username, token }) => {
    switch (type) {
        case actionTypes.AUTHORIZE:
            saveCredentials({ token, username })
            return {
                authorized: true,
                token,
                username
            }
        case actionTypes.DEAUTHORIZE:
            removeCredentials()
            return { ...initialState }
        default:
            return { ...state }
    }
}